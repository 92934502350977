import React from "react";

import '../style/Contact.css'
import Forms from "./Forms";

import linkedin from '../images/icons/linkedin.png'
import instagram from '../images/icons/logotipo-do-instagram.png'
import youtube from '../images/icons/youtube-icon.png'
import localizacao from '../images/icons/localizacao.png'
import email from '../images/icons/o-email.png'
import telefone from '../images/icons/chamada-telefonica.png'

function Contact(){
    return <div  id="pag-contato">

        <section id='area-text'>
           <div>
            <h1> Entre em contato conosco</h1>
            <p className="text-green">Atendemos todo o Brasil!</p>
           </div>
        </section>

        <section id="area-form">

            <Forms />

            <div className="social-media">
                <div>
                    <h3>Informações para contato</h3>
                    
                    <div className="informations">
                        <img src={localizacao}/>
                        <p>Rua Alaska, 344 - Jardim Canada, Nova Lima - MG, 34007-718</p>
                    </div>
                    <div className="informations">
                        <img src={email}/>
                        <p>comercial@lokmax.com.br</p>
                    </div>
                    <div className="informations">
                        <img src={telefone}/>
                        <p>+55 (31) 2581-9703</p>
                    </div>
                </div>

                <div className="links">

                    <div className="icons">
                        <a href="https://www.linkedin.com/company/lokmaxltda/"><img src={linkedin}/></a>
                    </div>
                    <div className="icons">
                        <a href="https://www.youtube.com/@LOKMAX"><img src={youtube}/></a>
                    </div>
                    <div className="icons">
                        <a href="https://www.instagram.com/lokmax_/"><img src={instagram}/></a>
                    </div>
                </div>
                
            </div>
        </section>



    </div>
}

export default Contact