import React from "react";
import { Link } from "react-router-dom";
import firstSlide from '../images/slide-desktop/first-slide.webp'
import secondSlide from '../images/slide-desktop/second-slide.webp'
import thirdSlide from '../images/slide-desktop/third-slide.webp'
import novidade from '../images/slide-desktop/novidade.webp'
import '../style/HomePage.css'

function Slides() {
    return <>


        {/* <!--Carousel--> */}
        <section id="topo" className=" mb-3">
          {/* <!--Carrousel Desktop--> */}
          <div id="carousel_desktop" className="carousel slide z-0 " data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carousel_desktop" data-bs-slide-to="0" className="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carousel_desktop" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carousel_desktop" data-bs-slide-to="2" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carousel_desktop" data-bs-slide-to="3" aria-label="Slide 4"></button>
            </div>
            
            <div className="carousel-inner">
              {/* <!--First slide--> */}
              <div className="carousel-item active">
                <img src={novidade} alt="..." />
                <div id="f_slide" className="carousel-caption">
                  <h1>Novidade </h1>
                  <p>Combinação de tecnologia avançada e baixo consumo para reultados imbatíveis </p>
                  <Link to='XMR403SVT' className="button-green"> Clique e saiba mais</Link>
                </div> 
              </div>
              <div className="carousel-item ">
                <img src={firstSlide} alt="..." />
                <div id="s_slide" className="carousel-caption">
                  
                  <p>Equipamentos <span className="text-green">inovadores</span> como você nunca viu.</p>
                  <Link to='capinadeiras' className="button-green"> Clique e saiba mais</Link>
                </div> 
              </div>
              
              {/* <!--Second slide--> */}
              <div className="carousel-item">
              <img src={secondSlide} alt="..." />
                <div id="t_slide" className="carousel-caption ">
                  <p>Locação de máquinas e serviços com <span className="text-green"> soluções completas </span>. </p>
                  <Link to='mini-carregadeiras' className="button-green"> Clique e saiba mais</Link>
                </div>
              </div>

              {/* <!--Third slide--> */}
              <div className="carousel-item">
              <img src={thirdSlide} alt="..." />
                <div id="u_slide" className="carousel-caption">
                  <p> A Lokmax tem a mini escavadeira que<span className="text-green"> você precisa!</span></p>
                  <Link to='mini-escavadeiras' className="button-green"> Clique e saiba mais</Link>
                </div> 
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carousel_desktop" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carousel_desktop" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
            
          </div>   

      
        </section>



      
    </>
}

export default Slides;