import React from "react";
import '../style/LadingPage.css';
import {Link} from 'react-router-dom';

import PopUp from '../components/PopUp';
import Footer from '../components/Footer';

import firstSlide from '../images/ladingPage/slides/5.png'
import secondSlide from '../images/ladingPage/slides/6.png'
import thirdSlide from '../images/ladingPage/slides/7.png'
import fourthSlide from '../images/ladingPage/slides/8.png'
import portfolio from '../XMR403SVT-V1-Agosto-2023.pdf'
import logo from '../images/logos/main-logo-white.png'
import menuBar from '../images/icons/hamburgue-menu.png'
import fechar from '../images/icons/close.png'
import Navbar from '../components/Navbar';
import seta from '../images/icons/seta.png'
import firstSlideMobile from '../images/ladingPage/slides/1.png'
import secondSlideMobile from '../images/ladingPage/slides/2.png'
import thirdSlideMobile from '../images/ladingPage/slides/3.png'
import fourthSlideMobile from '../images/ladingPage/slides/4.png'
import logoHorizontal from '../images/logos/lokmax-yellow.png'
import linkIcon from '../images/icons/link-icon-white.png'
import wppIcon from '../images/icons/whatsapp-green.png'
import faceIcon from '../images/icons/facebook-icon-origin.png'
import youtubeIcon from '../images/icons/youtube-icon-vermelho.png'
import instaIcon from '../images/icons/instagram-icon-origin.png'
import telephone from '../images/icons/telephone-green.png'
import email from '../images/icons/email-green.png'
import linkedIcon from '../images/icons/linkedin-icon-azul.png'
import Carrossel from "./SlidesLadingPage";

function LadingPage(){
    return<>
        <header id="ladingPage">
            <div className='area-logo'><Link   to="/"><img className="logo" src={logo} /></Link></div>


            <input type="checkbox" id="menu-bar" />
            <label className='active' for="menu-bar"><img src={menuBar} width="25px" /> </label>
            <label className='closeIcon' for="menu-bar"><img src={fechar} width="25px" /> </label>

            <Navbar />
            <PopUp />


        </header>


        {/*Image - text*/}
        <section id="apresentacao-incial">
            <div id="title-pag">

                <div className="name-equipment"> 
                    <h1>
                        Rolo Compactador
                    </h1>
                    <p>XMR403SVT</p>
                </div>

                <div id="seta"><img src={seta} /></div>

                <div className="area-text">
                    <p>
                        O rolo compactador vibratório combinado XMR403SVT foi projetado considerando a inovação tecnológica e o 
                        design humanizado que caracterizam os compactadores XCMG, com um aspecto otimizado que gera uma redução 
                        de consumo de combustível e emissão de ruído .<br/>
                        <br/>
                        Com avançado desempenho, alta confiabilidade e eficiência, está desenhado para trabalhar em rodovias, vias 
                        municipais e estacionamentos. Este modelo de compactador combinado vem equipado com motor Perkins, refrigerado a 
                        água e pode trabalhar até uma altitude de 3000 metros. O sistema hidráulico é composto por bomba de pistões,
                        motor de translação de baixa velocidade e alto torque
                    </p>
                </div>
                
            </ div>
        </section>

        {/* DataSheet */}
        <section id="data-sheet" >
            <div>
                <h2>Características de desempenho: </h2>
                <Carrossel />

            </div>  
        </section>

        <section className="esp-tecnica">
            <h1>
                Explore mais detalhes baixando o portfólio completo.
            </h1>
            <a className="button-yellow" href={portfolio} download="">Baixar portfólio</a>
        </section>

        <section id="footer">
            <footer id="lading-page-footer">
                <section id="image-lokmax">
                    <div id="area-img">
                        <img src={logoHorizontal}/>
                        <p>Qualidade e produtividade para a sua obra!</p>
                    </div>
                    
                    <div id="map">
                        <h5>Mapa do site:</h5>
                        
                        <ul >
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="index.html">Home</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" href="blog.html" target="_blank">Blog</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="sobre_nos.html">Sobre Nós</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="contato.html"> Contato</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Trabalhe Conosco</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Avalie-nos</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                            <li className="d-flex">
                                <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Contrato</a>
                                <img className="ms-2" src={linkIcon} />
                            </li>
                        </ul>

                    </div>

                </section>

                {/* <!--Contact--> */}
                <section id="contact">
                        <h5>ENTRE EM CONTATO CONOSCO: </h5>
                        <ul>
                        {/* <!-- whatsapp --> */}
                        <li className="d-flex ">
                            <img src={wppIcon} width="25px" />
                            <a target="_blank" href=" https://api.whatsapp.com/send/?phone=5531991518267&text">+55 (31) 99151-8267 </a>
                            <img src={linkIcon} className="ms-2" width="10px" height="10px" />
                        </li>
            
                        {/* <!-- telephone --> */}
                        <li  className="d-flex">
                            <img src={telephone}width="24px" />
                            <a>+55 (31) 2581-9703</a>
                        </li>
            
                        {/* <!-- email --> */}
                        <li className="d-flex">
                            <img src={email} width="25px" />
                            <a target="_blank" href="mailto:comercial@lokmax.com.br?subject=subject text">comercial@lokmax.com.br</a>
                            <img src={linkIcon} className="ms-2" width="10px" height="10px" />
                        </li>
                        </ul>

                        <div id="social-media">
                            <h5>
                            SIGA-NOS:
                            </h5>
                
                            <ul>
                            {/* <!-- LINKEDIN --> */}
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/company/lokmaxltda/"><img src={linkedIcon} /></a>
                            </li>
                
                            {/* <!-- YOUTUBE --> */}
                            <li>
                                <a target="_blank" href="https://www.youtube.com/@LOKMAX"><img src={youtubeIcon} /></a>
                            </li>
                
                            {/* <!--INSTAGRAM--> */}
                            <li>
                                <a target="_blank" href="https://www.instagram.com/lokmax_/"><img src={instaIcon} /></a>
                            </li>
                            {/* <!--INSTAGRAM--> */}
                            <li>
                                <a target="_blank" href="https://www.facebook.com/frederico.lokmax"><img src={faceIcon} /></a>
                            </li>
                
                            </ul>
                
                        </div>
                </section>
            </footer>
        </section>
    </>
}

export default LadingPage;