import React from "react";
import { BrowserRouter as Router, Link} from 'react-router-dom';

import menuBar from '../images/icons/hamburgue-menu.png'
import fechar from '../images/icons/close.png'
import Navbar from '../components/Navbar';
import logo from '../images/logos/main-logo-green.png';

function Header(){
    return     <header>
        <div className='area-logo'><Link   to="/"><img className="logo" src={logo} /></Link></div>


    <input type="checkbox" id="menu-bar" />
    <label className='active' for="menu-bar"><img src={menuBar} width="25px" /> </label>
    <label className='closeIcon' for="menu-bar"><img src={fechar} width="25px" /> </label>

    <Navbar />
    

        <nav id="topo" className="navbar">
        <ul>
            <li><a href="https://www.emestoque.com.br/lokmax">Seminovos</a></li>
            <li><span>|</span></li>
            <li><a href="https://spa.checklistfacil.com.br/apply/b4c3c138-32d4-11ee-a82d-0e431841a12b/category/2205171">Avalie-nos </a></li>
            <li><span>|</span></li>
            <li><a href="https://lokmax.solides.jobs/">Trabalhe conosco</a></li>
        </ul>
    </nav>

</header>
}

export default Header;