import React from "react";

import instagramIconWhite from '../images/icons/intagram-icon-white.png'
import linkedinIconWhite from '../images/icons/linkedin.png'
import youtubeIconWhite from '../images/icons/youtubeIconWhite.png'
import whatsappIcon from '../images/icons/whatsapp-green.png'

function PopUp(){
    return <>
        <section className="areaSocialMedia desk-hidden">
            <ul>
                <li><a target="_blank" href="https://www.linkedin.com/company/lokmaxltda/"><img src={linkedinIconWhite} /></a></li>
                <li><a target="_blank" href="https://www.youtube.com/@LOKMAX"><img src={youtubeIconWhite} /></a></li>
                <li><a target="_blank" href="https://www.instagram.com/lokmax_/"><img src={instagramIconWhite} /></a></li>
            </ul>
        </section>
        <section className="popUpWhatsApp">
            <p className="desk-hidden">Fale com um vendedor!</p>
            <a target="_blank" href="https://api.whatsapp.com/send/?phone=5531991518267&text"><img src={whatsappIcon} /></a>
        </section>
    </>
}

export default PopUp