import mini_escavadeira_2t from '../images/equipmentsImages/escavadeira.png'
import mini_escavadeira_4t_cabinada from '../images/equipmentsImages/ME-ET35.png'
import mini_escavadeira_4t from '../images/equipmentsImages/ME-EZ36.png'
import mini_escavadeira_6t_cabinada from '../images/equipmentsImages/ET66-5a6.png'
import mini_escavadeira_6t from '../images/equipmentsImages/50Z3.png'
import mini_escavadeira_8t from '../images/equipmentsImages/ET75-6a8.png'

//importação das imagens das mini carregadeiras
import mini_carregadeira_cabinada from '../images/equipmentsImages/MC-SW16.png'
import mini_carregadeira from '../images/equipmentsImages/MC-701.png'
import manipulador from '../images/equipmentsImages/Telemanipulador.png'

//importação das imagens dos mini dumpers
import dumper_cnf from '../images/equipmentsImages/cnf-t95_18.png'
import dumper_wn from '../images/equipmentsImages/dumper-dt10.png'
import dumper_pneu from '../images/equipmentsImages/AR-16-removebg-preview.png'

//importação das imagens dos compactadores
import controle_remoto from '../images/equipmentsImages/rolo-pe-de-carneio.png'
import rolo_tripulado from '../images/equipmentsImages/RD-12-removebg-preview.png'

//importação das imagens das valetadeiras 
import valetadeira_manual from '../images/equipmentsImages/valetadeira-sf-rtx250.png'
import valetadeira_tripulada from '../images/equipmentsImages/valetadeias-RT45.png'
import valetadeira_hidraulica from '../images/equipmentsImages/valetadeira-hidraulica.png'

//importação das imagens das esteiras modulares
import esteiras_modular_aco from '../images/equipmentsImages/esteirasModulares-semFundo.png'
import esteiras_modular_plas from '../images/equipmentsImages/esteira-de-plastico-removebg-preview.png'

//importação das imagens das roçadeiras e capinadeiras
import rocadeira from '../images/equipmentsImages/rocadeia-f23-greenclimber-1.png'
import rocadeira_hidraulica from '../images/equipmentsImages/rocadeiraHidraulica.png'

//importação das imagens dos implementos
import marteloRompedor from '../images/equipmentsImages/rompedorHidraulico.png'
import perfuratriz from '../images/equipmentsImages/perfuratriz-imp.png'
import fresadora from '../images/equipmentsImages/Fresadora_3-removebg-preview.png'
import vassoura_hidraulica from '../images/equipmentsImages/vassoura-hidraulica-implementos-lokmax.png'
import capinadeira_hidraulica from '../images/equipmentsImages/capinadeira-hidraulica.png'
import garfo_pallet from '../images/equipmentsImages/garfo-im.png'


const equipmentsData = [

  /* MINI ESCAVADEIRAS*/
    { id: "1", tipo: "mini-escavadeiras", modelo: "Mini escavadeira 1t a 2,8t ", image : mini_escavadeira_2t , 
        especificacao: {
            "Peso Operacional": "1.600 kg a 2.800kg ",
            "Profundidade de escavação": "2,20m a 2,74 m ",
            "Largura da caçamba":"0,20m a 0,45m",
            "Altura do equipamento":"2,30m a 2,40m",
            "Largura do equipamento":"0,99m a 1,60m",
            "Altura de descarga":"2,50m a 2,85m",
            "Capacidade de carga do braço":"160kg a 400kg",
            "Combustível": "DIESEL - consumo de 3l/h a 4l/h"
         } },


    {id: "2", tipo: "mini-escavadeiras", modelo: " Mini escavadeira 3t a 4,5t cabinada ",  image : mini_escavadeira_4t_cabinada , 
       especificacao: {
        "Peso Operacional": "3.415kg a 3.825kg ",
        "Profundidade de escavação": "2,97m a 3,25m ",
        "Largura da caçamba":"0,30m a 0,60m",
        "Altura do equipamento":"2,46m a 2,50m",
        "Largura do equipamento":"1,55m  a 1,75m",
        "Altura de descarga":"3,00m a 3,41m",
        "Capacidade de carga do braço":"500kg a 600kg",
        "Combustível": "DIESEL - consumo de 4l/h a 5l/h"
     } },

     {id: "3", tipo: "mini-escavadeiras", modelo: "Mini escavadeira 3t a 4,5t cabine aberta ",  image : mini_escavadeira_4t , 
      especificacao: {
       "Peso Operacional": "3.550kg ",
       "Profundidade de escavação": "3,25",
       "Largura da caçamba":"0,30m a 0,60m",
       "Altura do equipamento":"2,50m",
       "Largura do equipamento":"1,63m",
       "Altura de descarga":"3,33m ",
       "Capacidade de carga do braço":"600kg",
       "Combustível": "DIESEL - consumo de 4l/h a 5l/h"
    } },

     {id: "4", tipo: "mini-escavadeiras", modelo: "Mini escavadeira 5t a 6t cabinada ",  image : mini_escavadeira_6t_cabinada , 
      especificacao: {
       "Peso Operacional": "5.465kg a 5.780kg ",
       "Profundidade de escavação": "3,71m a 3,25m ",
       "Largura da caçamba":"0,40m a 0,70m",
       "Altura do equipamento":"2,54m a 2,60m",
       "Largura do equipamento":"2,00m",
       "Altura de descarga":"3,93m a 4,24m",
       "Capacidade de carga do braço":"1.060kg a 1.200kg",
       "Combustível": "DIESEL - consumo de 5l/h a 7l/h"
    } },
    {id: "5", tipo: "mini-escavadeiras", modelo: "Mini escavadeira 5t a 6t cabine aberta ",  image : mini_escavadeira_6t , 
      especificacao: {
       "Peso Operacional": "5.240kg",
       "Profundidade de escavação": "3,40m",
       "Largura da caçamba":"0,40m a 0,60m",
       "Altura do equipamento":" 2,67m",
       "Largura do equipamento":"1,99m",
       "Altura de descarga":"3,79m",
       "Capacidade de carga do braço":"1.060kg",
       "Combustível": "DIESEL - consumo de 5l/h a 7l/h"
    } },
    {id: "6", tipo: "mini-escavadeiras", modelo: "Mini escavadeira 6.5t a 8t cabinada",  image : mini_escavadeira_8t , 
      especificacao: {
       "Peso Operacional": "6.250kg a 7.300kg",
       "Profundidade de escavação": "3,87m a 4,09m",
       "Largura da caçamba":"0,40m a 0,75m",
       "Altura do equipamento":" 2,56m",
       "Largura do equipamento":"2,10m a 2,25m",
       "Altura de descarga":"3,99m a 5,15m",
       "Capacidade de carga do braço":"1.200kg a 1.500kg",
       "Combustível": "DIESEL - consumo de 5l/h a 7l/h"
    } },



    //MINI CARREGADEIRAS 
    {id: "7", tipo: "mini-carregadeiras", modelo: "Mini carregadeira cabinada",  image : mini_carregadeira_cabinada , 
      especificacao: {
       "Peso Operacional": "2.200kg a 3.777kg",
       "Opção com e sem Highflow": "",
       "Capacidade de carga":"750kg a 1.300kg",
       "Altura de despejo":" 2,30m a 3,20m",
       "Altura do equipamento":"2,00m a 2,06m",
       "Largura do equipamento":"1,60m a 2,00m",
       "Combustível": "DIESEL - consumo de 3l/h a 5l/h"
    } },

    {id: "8", tipo: "mini-carregadeiras", modelo: "Mini carregadeira cabine aberta",  image : mini_carregadeira , 
      especificacao: {
       "Peso Operacional": "2.200kg a 2.800kg",
       "Não possui Highflow": "",
       "Capacidade de carga":"750kg a 800kg",
       "Altura de despejo":" 2,80m a 3,00m",
       "Altura do equipamento":"2,00m",
       "Largura do equipamento":"1,60m a 2,00m",
       "Combustível": "DIESEL - consumo de 3l/h a 5l/h"
    } },

    //MANIPULADOR
    {id: "9", tipo: "manipuladores", modelo: "Manipulador telescópio ERT1500",  image : manipulador , 
      especificacao: {
       "Peso Operacional": "2.800kg",
       "Sem Highflow": "",
       "Capacidade de carga":"500kg a 1.300kg",
       "Altura de despejo":" 4,00m",
       "Altura do equipamento":"1,95m",
       "Largura do equipamento":"1,44m",
       "Combustível": "DIESEL - consumo de 3l/h a 4l/h"
    } },

    //Dumper
    {id: "11", tipo: "dumpers", modelo: "Mini dumper autocarregável de esteira com high tip ",  image : dumper_cnf , 
      especificacao: {
       "Peso Operacional": "500kg",
       "Capacidade de carga":"900kg",
       "Capacidade da caçamba":"0,36m³",
       "Largura do equipamento":"0,81m",
       "Altura máxima do equipamento":"1,42m",
       "Combustível": "DIESEL ou GASOLINA"
    } },
    {id: "12", tipo: "dumpers", modelo: "Mini dumper autocarregável de esteira ",  image :dumper_wn , 
      especificacao: {
       "Peso Operacional": "695kg",
       "Capacidade de carga":"1.000kg",
       "Capacidade da caçamba":"0,42m³",
       "Largura do equipamento":"1,28m",
       "Altura do equipamento":"0,83m",
       "Combustível": "DIESEL - consumo de 3l/h a 5l/h"
    } },
    {id: "13", tipo: "dumpers", modelo: "Mini dumper de pneu ",  image : dumper_pneu , 
      especificacao: {
       "Peso Operacional": "658kg",
       "Capacidade de carga":"1.200kg",
       "Capacidade da caçamba":"0,45m³",
       "Largura do equipamento":"1,19m",
       "Altura do equipamento":"1,27m",
       "Combustível": "DIESEL - consumo de 3l/h a 5l/h"
    } },

    //COMPACTADORES
    {id: "14", tipo: "compactadores", modelo: "Mini rolo de controle remoto ",  image : controle_remoto , 
      especificacao: {
       "Peso Operacional": "1.500kg",
       "Força de compactação":"60kN",
       "Tipo de compactação":"Chapa pé de carneiro ou chapa lisa",
       "Frequência de vibração":"42Hz",
       "Dimensões (C x L x A)":"1,90m x 0,82m x 1,30m",
       "Velocidade de trabalho":"41 m/min",
       "Combustível": "DIESEL - consumo de 2l/h a 3l/h"
    } },
    {id: "15", tipo: "compactadores", modelo: "Mini rolo tripulado",  image : rolo_tripulado , 
      especificacao: {
       "Peso Operacional": "1.300kg",
       "Força de compactação":"15kN",
       "Tipo de compactação":"Chapa lisa",
       "Frequência de vibração":"70Hz",
       "Dimensões (C x L x A)":"1,90m x 0,99m x 2,30m",
       "Velocidade de trabalho":"133 m/min",
       "Combustível": "DIESEL - consumo de 2l/h a 3l/h"
    } },

    //VALETADEIRAS
    {id: "16", tipo: "valetadeiras", modelo: "Valetadeira manual",  image : valetadeira_manual , 
      especificacao: {
       "Peso Operacional": "664kg a 860kg",
       "Profundidade de escavação":"0,80m a 1,20m",
       "Largura de escavação":"0,10m a 0,20m",
       "Largura do equipamento":"0,84m a 0,90m",
       "Altura do equipamento":"1,15m a 1,20m",
       "Combustível": "DIESEL - consumo de 3l/h a 4l/h"
    } },
    {id: "17", tipo: "valetadeiras", modelo: "Valetadeira tripulada",  image : valetadeira_tripulada , 
      especificacao: {
       "Peso Operacional": "2.348kg",
       "Profundidade de escavação":"1,70m",
       "Largura de escavação":"0,10m a 0,30m",
       "Largura do equipamento":"1,20m",
       "Altura do equipamento":"2,20m",
       "Combustível": "DIESEL - consumo de 3l/h a 4l/h"
    } },
   
    //ESTEIRAS MODULARES
    {id: "19", tipo: "esteiras-modulares", modelo: "Esteira modular de aço",  image : esteiras_modular_aco , 
      especificacao: {
       "Voltagem por módulo": "Trifásico 220V",
       "Peso":"115kg",
       "Comprimento":"3,10m",
       "Dimensões (L x A)":"70cm x 50cm",
       "Motor": "1cv"
    } },
    {id: "20", tipo: "esteiras-modulares", modelo: "Esteira modular de plático",  image : esteiras_modular_plas , 
      especificacao: {
       "Voltagem por módulo": "Trifásico 220V",
       "Peso":"50kg",
       "Comprimento":"3m",
       "Dimensões (L x A)":"60cm x 26cm",
       "Motor": "0,27cv"
    } },
    
    //Capinadeiras 
    {id: "21", tipo: "capinadeiras", modelo: "Capinadeira de controle remoto",  image : rocadeira , 
      especificacao: {
        "Peso Operacional": "470kg",
        "Produtividade média":"5.000 m²/h",
        "Velocidade do trabalho":"7 a 10 km/h",
        "Largura do corte":"0,80m",
        "Largura do equipamento":"1,26m",
        "Altura do equipamento":"0,76m",
        "Combustível": "GASOLINA - consumo de 5l/h a 6l/h"
     } },
     {id: "22", tipo: "capinadeiras", modelo: "Roçadeira hidraulica",  image : rocadeira_hidraulica , 
      especificacao: {
        "Peso Operacional": "1.030kg",
        "Largura de corte":"365cm",
        "Altura de corte":"4m a 10m",
        "Largura do equipamento":"285cm",
        "Altura do equipamento":"102cm",
        "OBS: Este produto é um implemento": "Acoplável em trator com tomada de força acima de 55kw"
     } },

     //IMPLEMENTOS
     {id: "23", tipo: "implementos", modelo: "Martelo rompedor",  image : marteloRompedor , 
      especificacao: {
        "Peso Operacional": "1.030kg",
        "OBS: Este produto é um implemento": "Acoplável em trator com tomada de força acima de 55kw"
     } },

     {id: "24", tipo: "implementos", modelo: "Garfo pallet",  image : garfo_pallet , 
      especificacao: {
        "Capacidade de carga ": "Conforme modelo acoplado",
        "Opções": "Convencional e giratório",
        "Acoplável em: ":"Mini carregadeira"
     } },
     {id: "25", tipo: "implementos", modelo: "Perfuratriz",  image : perfuratriz , 
      especificacao: {
        "Peso Operacional": "115kg a 300kg",
        "Profundidade de perfuração":"Até 2,00m sem extensor",
        "Diâmetro de perfuração":"0,15m a 0,80m",
        "Acoplável em: ": "Mini escavadeiras e mini carregadeiras"
     } },
     
     {id: "26", tipo: "implementos", modelo: "Roçadeira hidraulica",  image : rocadeira_hidraulica , 
      especificacao: {
        "Peso Operacional": "1.030kg",
        "Largura de corte":"365cm",
        "Altura de corte":"4m a 10m",
        "Largura do equipamento":"285cm",
        "Altura do equipamento":"102cm",
        "Acoplável em: ": "Tator com tomada de força acima de 55kw"
     } },
     {id: "27", tipo: "implementos", modelo: "Fresadoras",  image : fresadora , 
      especificacao: {
        "Peso Operacional": "708kg a 766kg",
        "Largura de corte":"18'' a 24''",
        "Profundidade de corte":"6''",
        "Deslocamento lateral":"24'' (0,61m)",
        "Inclinação":"15°",
        "Acoplável em:": "Mini carregador com highflow"
     } },
     
     {id: "28", tipo: "implementos", modelo: "Vassoura Hidraulica",  image : vassoura_hidraulica , 
      especificacao: {
        "Peso Operacional": "600kg",
        "Volume da caçamba":"0,34m³",
        "Largura total":"1,70m",
        "Cerdas":"32 cerdas",
        "Acoplável em: ": "Mini carregadeiras"
     } },

     {id: "30", tipo: "implementos", modelo: "Capinadeira hidraulica",  image : capinadeira_hidraulica , 
      especificacao: {
        "Peso Operacional": "400kg",
        "Cabo de aço":"CAP 40 x 3,4'' - com alma de aço",
        "Largura do equipamento":"1,80m",
        "Ajustável":"3 posições",
        "Acoplável em: ": "Mini Carregadeira"
     } }
  ];

export default equipmentsData