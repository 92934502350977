import React, { useState } from 'react';
import '../style/Carrossel.css';
import firstSlide from '../images/ladingPage/slides/5.webp'
import secondSlide from '../images/ladingPage/slides/6.webp'
import thirdSlide from '../images/ladingPage/slides/7.webp'
import fourthSlide from '../images/ladingPage/slides/8.webp'
import firstSlideMobile from '../images/ladingPage/slides/1.webp'
import secondSlideMobile from '../images/ladingPage/slides/2.webp'
import thirdSlideMobile from '../images/ladingPage/slides/3.webp'
import fourthSlideMobile from '../images/ladingPage/slides/4.webp'

const images = [
  firstSlide, secondSlide, thirdSlide, fourthSlide
];

const imagesMobile = [
    firstSlideMobile, secondSlideMobile, thirdSlideMobile, fourthSlideMobile
  ];


const texts = [
    'O tambor de aço dianteiro utiliza a sua qualidade de distribuição eficiente e a sua força de excitação para reduzir a fricção entre as partículas do material triturado, realizando a junção total e compactação do material de asfalto e outros agregados, o que gera uma superfície de pavimento com uma boa resistência ao desgaste',
    'A rodas traseiras utilizam da deformação do pneu de borracha para garantir a área de contato entre o pneu e o material compactado, aumentando a impermeabilidade da superfície, o que gera uma compactação mais uniforme das partículas e uma vida útil mais longa.',
    'O sistema de direção adota uma direção totalmente hidráulica, facilitando a direção e as manobras; Tração integral com forte adaptabilidade, velocidade variável contínua, podendo o condutor escolher a velocidade de condução necessária de acordo com as diferentes condições de trabalho.',
    'O capô pode ser levantado a um grande ângulo, e todos os pontos de manutenção do sistema de energia podem ser facilmente alcançados; '
];

function Carrossel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="carrossel">
        <button className="carrossel-button prev" onClick={prevSlide}>
            &#10094;
        </button>
        <div className="carrossel-images desktop">
            {images.map((image, index) => (
                <div key={index} className={index === currentIndex ? 'slide active' : 'slide'}>
                <img
                    src={image}
                    alt={`Slide ${index}`}
                    className={index === currentIndex ? 'active' : ''}
                />
                <div className="carrossel-text">
                    {/* Verifica se o índice do texto corresponde ao índice da imagem */}
                    {index === currentIndex && (
                    <p>
                        {texts[index]} {/* Exibe o texto correspondente ao slide atual */}
                    </p>
                    )}
                </div>
                </div>
            ))}
        </div>

        <div className="carrossel-images mobile">
            {imagesMobile.map((image, index) => (
                <div key={index} className={index === currentIndex ? 'slide active' : 'slide'}>
                <img
                    src={image}
                    alt={`Slide ${index}`}
                    className={index === currentIndex ? 'active' : ''}
                />
                <div className="carrossel-text">
                    {/* Verifica se o índice do texto corresponde ao índice da imagem */}
                    {index === currentIndex && (
                    <p>
                        {texts[index]} {/* Exibe o texto correspondente ao slide atual */}
                    </p>
                    )}
                </div>
                </div>
            ))}
        </div>

        <button className="carrossel-button next" onClick={nextSlide}>
            &#10095;
        </button>
    </div>

  );
}

export default Carrossel;
