import React, { useEffect, useRef } from 'react';
import '../style/AboutUs.css'
import logoAntigo from '../images/logos/logolokmax.webp'
import logoNew from '../images/logos/lokmax-green.png'

function EffectLogo() {
  const oldLogoRef = useRef(null);
  const newLogoRef = useRef(null);

  useEffect(() => {
    const gsap = window.gsap;

    gsap.to(oldLogoRef.current, {opacity: 0, duration: 2});
    gsap.fromTo(newLogoRef.current, {opacity: 0}, {opacity:1, duration: 2, delay:2})

  }, []);
    return (
      <section id='area-logos'>
            <img className='animation'
              src={logoAntigo}
              alt="Logo Antiga"
              ref={oldLogoRef}
              style={{ position: 'absolute', transition: 'opacity 1s' }}
            />
            <img className='animation'
              src={logoNew}
              alt="Logo Nova"
              ref={newLogoRef}
              style={{  opacity:0, transition: 'opacity 1s' }}
            />
        
      </section>
  )
}

export default EffectLogo;
