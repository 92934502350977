import React from "react";
import '../style/HomePage.css'
import Slides from './SlidesHomePage'
import Customers from './CustomerCarousel'

import controleRemoto from '../images/rolo-pe-de-carneio.png'
import miniEscavadeira from '../images/mini-escavadeira ez26.png'
import miniCarregadeira from '../images/minicarregadeira-sw.png'
import valetadeiras from '../images/equipmentsImages/valetadeias-RT45.png'
import mpDumpers from '../images/most-researched/dumpers.png'
import mpMiniEscavadeiras from '../images/most-researched/mini-escavadeira.png'
import mpMiniCarregadeira from '../images/most-researched/mini-carregadeira.png'
import mpImplementos from '../images/most-researched/implementos.png'
import mpRolosCompactadores from '../images/most-researched/compactadores.png'
import mpRocadeiras from '../images/most-researched/rocadeiras.png'
import { Link } from "react-router-dom";
import portfolio from '../PORTFOLIO-LOKMAX.pdf'


function HomePage() {
    return <>

        <Slides />
        
        {/* <!--best equipment--> */}
        <section id="area-initial">
            {/* <!--Company presentation--> */}
            <div id="presentation">
                <h2> Mecanizando desde 2009</h2>
                <p>
                Somos uma empresa de locação e comercialização de equipamentos multicategoria, possuindo uma frota apta a
                atender diversos mercados e
                tipos de serviços. Contamos ainda com atendimento mecânico especializado e mão de obra capacitada na
                operação de nossos equipamentos.
                </p>
                <a className="animation-button" href={portfolio} download="">Baixar portfólio</a>
            </div>

            {/* <!--Best Equipments--> */}
            <div id="best-equipment">
                <div className="best-equipment-area">

                <div className="card">
                    <div className="area-img"><img className="" src={controleRemoto} /></div>
                    <p>Mini rolo de controle remoto </p>
                    <Link to="/equipamento/compactadores" className="button-green">Saiba mais...</Link>
                </div>

                <div className="card">
                    <div className="area-img"><img className="" src={miniEscavadeira} /></div>
                    <p> MINI ESCAVADEIRA DE 3,5T</p>
                    <Link to="/equipamento/mini-escavadeiras" className="button-green">Saiba mais...</Link>
                </div>

                <div className="card">
                    <div className="area-img"><img className="" src={miniCarregadeira}/></div>
                    <p>MINI CARREGADEIRA CABINE FECHADA </p>
                    <Link to="/equipamento/mini-carregadeiras" className="button-green">Saiba mais...</Link>
                </div>

                <div className="card">
                    <div className="area-img"><img className="" src={valetadeiras}/></div>
                    <p>Valetadeira tripulada </p>
                    <Link to="/equipamento/esteiras-modulares" className="button-green">Saiba mais...</Link>
                </div>

                </div>
            </div>

        </section>

        

        {/* <!--most researched equipment--> */}
        <section id="most-researched">
          <div className="researched">

            <h2>Mais Procurados: </h2>

            <div className="area-equip">

              {/* <!--DUMPERS--> */}
              <div className="equip">
                
                <div className="content">
                  <div className="descricao">
                      <h3>Dumpers</h3>
                      <p>
                        A linha de dumpers são qualificados para transportar materiais com mais facilidade e em um menor
                        tempo.
                      </p>
                      <Link to="/dumpers" className="text-green">Ver mais...</Link>
                    </div>
                    <div className="area-imge"><img src={mpDumpers} /></div>
                  
                </div>

              </div>

              {/* <!--MINI ESCAVADEIRAS--> */}
              <div className="equip">
                
                <div className="content">
                  <div className="descricao me-1">
                    <h3>Mini Escavadeiras</h3>
                    <p>
                      Nossos modelos de mini escavadeira com giro zero possibilita escavações próximas a vigas,
                      muros e paredes.
                    </p>
                    <Link to="mini-escavadeiras" className="text-green">Ver mais...</Link>
                  </div>
                  <div className="area-imge"><img src={mpMiniEscavadeiras} /></div>
                  
                </div>
              </div>

              {/* <!--ROLOS COMPACTADORES--> */}
              <div className="equip">
                
                <div className="content">
                  <div className="descricao me-1">
                      <h3>Rolos Compactadores</h3>
                      <p>
                        Possuímos ampla frota de rolos compactadores quem oferecerem os melhores resultados na compactação de
                        solos e asfaltos.
                      </p>
                      <Link to="/compactadores" className="text-green">Ver mais...</Link>
                    </div>
                  <div className="area-imge"><img src={mpRolosCompactadores} width="200px" height="150px" /></div>
                  
                </div>
              </div>

              {/* <!--MINI CARREGADEIRA--> */}
              <div className="equip">
              
                <div className="content">
                  <div className="descricao me-1">
                    <h3>Mini Carregadeiras</h3>
                      <p>
                        As Mini Carregadeiras são ideais para movimentação em pequenos espaços pelafacilidade de manobra.
                      </p>
                      <Link to="/mini-carregadeiras" className="text-green">Ver mais...</Link>
                    </div>
                  <div className="area-imge"> <img src={mpMiniCarregadeira}/></div>
                  
                </div>
              </div>

              {/* <!--IMPLEMENTOS--> */}
              <div className="equip">
                
                <div className="content">
                  <div className="descricao me-1">
                    <h3>Implementos </h3>
                      <p>
                        Possuimos implementos compativeis com mini escavadeiras e mini carregadeiras, que auxiliam de
                        diversas formas em uma obra.
                      </p>
                      <Link to="/implementos" className="text-green">Ver mais...</Link>
                    </div>
                  <div className="area-imge"> <img src={mpImplementos} width="150px" height="150px" />
                  </div>
                  
                </div>
              </div>

              {/* <!--ROÇADEIRAS E CAPINADEIRAS--> */}
              <div className="equip">
                
                <div className="content">
                <div className="descricao me-1">
                  <h3>Roçadeiras e Capinadeiras</h3>
                    <p>
                      As roçadeiras e capinadeiras são utilizados para aparar pequenas arvores ou ervas daninhas.
                    </p>
                    <Link to="/capinadeiras" className="text-green">Ver mais...</Link>
                  </div>
                  <div className="area-imge"><img src={mpRocadeiras} /></div>
   
                </div>
              </div>

            </div>

          </div>
        </section>

        <Customers />

       

    </>
}

export default HomePage;