import React from 'react';
import { useParams } from 'react-router-dom';
import equipmentsData from './EquipmentsData'
import '../style/RenderEquipamento.css'

/*importação das imagens da capa */
import miniCarregadeirasImg from "../images/equipmentCapa/miniCarregadeiras.webp"
import miniDumpersImg from "../images/equipmentCapa/miniDumpers.webp"
import miniEscavadeirasImg from "../images/equipmentCapa/miniEscavadeiras.webp"
import compactadoresImg from "../images/equipmentCapa/Compactadores.webp"
import valetadeirasImg from "../images/equipmentCapa/Valetadeiras.webp"
import esteirasModularesImg from "../images/equipmentCapa/esteirasModulares.webp"
import capinadeirasImg from "../images/equipmentCapa/Capinadeiras.webp"
import implementosImg from "../images/equipmentCapa/Implementos.webp"
import miniManipuladorImg from "../images/equipmentCapa/miniManipuladores.webp"


function RenderEquipamento() {
  const { tipoDoEquipamento } = useParams();
  var dados = DadosEquipamentos(tipoDoEquipamento);
  var img = dados.img
  var text = dados.text
  var typeEq = dados.typeEq

  /*Filtra os equipamentos pelo tipo selecionado*/
  const equipmentsForType = equipmentsData.filter(equipment => 
    equipment.tipo === tipoDoEquipamento
  );

  if (tipoDoEquipamento !== 'implementos'){
    return (
      <div id='renderEquipamento'>
        <div className='banner'>
          <img  src={img}/>
          <h1>{typeEq}</h1>
        </div>
        <p className='area-text'>{text}</p>
        
        <div id="equipment-area-imp"> 
          {equipmentsForType.map(equipment => (
            <CardTemplate key={equipment.id} equipment={equipment}/>
          ))}
        </div>
  
      </div>
    );
  }else {
    return (
      <div id='renderEquipamento'>
        <div className='banner'>
            <img  src={img}/>
            <h1>{typeEq}</h1>
        </div>
        <p className='area-text'>{text}</p>
        
        <div id="equipment-area"> 
          {equipmentsForType.map(equipment => (
            <CardTemplate key={equipment.id} equipment={equipment}/>
          ))}
        </div>
  
      </div>
    );
  }
}

function CardTemplate({equipment}){

  var linkcomprar = `https://api.whatsapp.com/send?phone=5531996486609&text=Olá!%20Tenho%20interesse%20em%20comprar:%20${equipment.modelo}`
  var linkalugar = `https://api.whatsapp.com/send?phone=5531991518267&text=Olá!%20Tenho%20interesse%20em%20alugar:%20${equipment.modelo}`
  if(equipment.tipo != 'implementos'){
      return <div>
          <div className="card-equipment">

          <div className="area-img">
              <h2 className="name-equipment">{equipment.modelo}</h2>
              <img src={equipment.image} /> 

              
                {/* <!-- BOTÕES DE COMPRAR - ALUGAR agora E BAIXAR MANUAL --> */}
                <div className="mt-2 area_botoes">
                  
                    <a className="button-dark "
                      href={linkcomprar} target="_blank">Desejo comprar</a>

                      <a className="button-green "
                      href={linkalugar} target="_blank"> Alugar agora</a>
                  </div>

            </div>


            <div className="list-area">
              <ul className="datasheet">
                  {Object.entries(equipment.especificacao).map(([key, value]) => (
                    <li>
                        <h6>{key}</h6>
                        <p>{value}</p>
                    </li>
                  ))}

              </ul>
            </div>
          </div>
      </div>
  }else  {
    return <div className="card-implementos">
          <h2 className="name-equipment">{equipment.modelo}</h2>

          <div className='area-img'><img src={equipment.image} /> </div>

          <div className="list-area">
              <ul className="datasheet">
                  {Object.entries(equipment.especificacao).map(([key, value]) => (
                    <li>
                        <h6>{key}</h6>
                        <p>{value}</p>
                    </li>
                  ))}

              </ul>

        </div>
        
            
        {/* <!-- BOTÕES DE COMPRAR - ALUGAR agora E BAIXAR MANUAL --> */}
        <div className="area_botoes">
          
            <a className="button-dark "
              href={linkcomprar} target="_blank">Desejo comprar</a>

              <a className="button-green "
              href={linkalugar} target="_blank"> Alugar agora</a>
          </div>


          
        </div>

  }
  
}

/*Define qual será o texto com base no tipo do equipamento*/
function DadosEquipamentos(tipoDoEquipamento){
  var text
  var img
  var typeEq

   switch(tipoDoEquipamento){
    case "mini-escavadeiras": 
      text = "As nossas mini escavadeiras são a escolha ideal para obras de construções civis e industriais, adequadas para movimentação em espaço reduzido, sendo indicadas nas escavações para redes elétricas, hidráulicas, esgoto cloacal, blocos estruturais e muito mais. Simplifique suas operações com nossas mini escavadeiras de alto desempenho."
      img = miniEscavadeirasImg;  
      typeEq = "Mini Escavadeiras"
      break
    case "mini-carregadeiras":
      text = "Descubra a solução perfeita para otimizar seus projetos em espaços reduzidos, entre uma variedade de modelos com capacidades operacionais que variam de 800kg a 1.600kg, as mini carregadeiras possuem uma incrível capacidade de manobra sendo máquinas chave para uma movimentação eficiente, proporcionando comodidade para atender às suas necessidades; desde o carregamento e descarga de materiais paletizados até o transporte através da caçamba. Nossas mini carregadeiras são uma escolha inteligente para o seu negócio."
      img = miniCarregadeirasImg;  
      typeEq = "Mini Carregadeiras"
      break
    case "manipuladores":
      text = "Os mini manipuladores possuem uma incrível capacidade de manobra sendo máquinas chave para uma movimentação eficiente, proporcionando comodidade para atender às suas necessidades; desde o carregamento e descarga de materiais paletizados até o transporte através da caçamba."
      img = miniManipuladorImg;  
      typeEq = "Mini Manipulador"
      break
    case "dumpers":
      text = "O Dumper é um equipamento inovador que possui uma solução ímpar para tornar a movimentação horizontal mais eficiente e eficaz, sendo ideal para o trabalho em canteiro de obras ou em plantas industriais, substituindo sempre que possível a mão de obra manual; possuindo um braço dianteiro articulado, que possibilita o auto carregamento de materiais desagregados e com sua caçamba basculável permite a descarga com mais facilidade."
      img = miniDumpersImg;  
      typeEq = "Dumpers e Mini Dumpers"
      break
    case "compactadores":
      text = "Seja eficiente em suas compactações com nossos Mini Rolos perfeitos para trabalhos em projetos de infraestrutura tais como, mas não se limitando a, rodovias, ferrovias, aeroportuários e em projetos urbanos como, estacionamentos, passeios públicos e locais confinados. Se a entrada do Mini Rolo é um desafio, nossas placas vibratórias são ideais para serviços ainda mais confinados e de locais de difícil acesso. Simplifique suas tarefas de compactação com a nossa gama de equipamentos."
      img = compactadoresImg;  
      typeEq = "Compactadores"
      break
    case "valetadeiras" : 
      text = "Nossas Valetadeiras são equipamentos ideais para escavar valas de 10cm a 30cm de largura, com produtividade e eficiência, pois suas larguras de escavações as tornam perfeitas para aberturas de valas destinadas a instalação de fibra óptica, interligação de torres eólicas e aterramento elétrico em subestações de energia. Simplifique o seu trabalho com as nossas valetadeiras de alta performance."
      img = valetadeirasImg;  
      typeEq = "Valetadeiras"
      break
    case "esteiras-modulares":
      text = "Transforme sua operação e economize tempo com as Esteiras Modulares, elas não apenas agilizam o trabalho, como também podem substituir a mão de obra manual; com módulos de 3 metros, essas esteiras possibilitam o transporte eficiente de materiais, seja na horizontal ou na vertical, superando desvios e inclinações."
      img = esteirasModularesImg;  
      typeEq = "Esteiras Modulares"
      break
    case "capinadeiras" : 
      text = "A Lokmax possui uma linha exclusiva para capina. Apresentamos nossa Capinadeira de Controle Remoto, fabricada pela renomada empresa italiana MDB, este cortador bidirecional de gramineas e ervas orgânicas é extremamente ágil e eficaz, capaz de enfrentar declives de até 60º em espaços confinados, adequadas para capina em fazendas fotovoltaicas, garantindo ao mesmo tempo a máxima eficiência e produtividade com total segurança durante a operação."
      img = capinadeirasImg;  
      typeEq = "Capinadeiras e Roçadeiras"
      break
    case "implementos" : 
      text = "Possuímos uma gama de opções de implementos indicados para Mini Carregadeiras e Mini Escavadeiras, tão diversificada quanto às tarefas requeridas, tais como, demolição com o Martelo Rompedor, escavação com Perfuratriz, reparos asfálticos com Fresadeira, limpeza e conservação com Vassouras e Capinadeiras Hidraulicas. As soluções oferecidas em Roçadeiras, podem ser facilmente acopladas a tratores de pneus, sendo ideais para a capina em fazendas fotovoltaicas."
      img = implementosImg;  
      typeEq = "Implementos"
      break

    }
    const dados = {
      text, 
      img,
      typeEq
    }

    return dados
}


export default RenderEquipamento;
