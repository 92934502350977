import './style/App.css';
import { BrowserRouter as Router, Link, Routes, Route, useLocation} from 'react-router-dom';

import RenderEquipamento from './components/RenderEquipamento';
import HomePage from './components/HomePage';
import ScrollTop from './components/ScrollTop';


import SobreNos from './components/AboutUs';
import Contato from './components/Contact';
import Footer from './components/Footer'

import PopUp from './components/PopUp';
import LadingPage from './components/LadingPage';
import Header from './components/Header';


import icone404 from './images/icons/pagina-nao-encontrada.png';


function App() {
  return (
    <Router>
        <div className="App">

          <ConditionalHeader />
          

          <main>
           
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/equipamento/:tipoDoEquipamento" element={<RenderEquipamento />} />
            <Route path="/sobre-nos" element={<SobreNos />} />
            <Route path="/contatos" element={<Contato />} />
            <Route path="/XMR403SVT" element={<LadingPage />} />
            <Route path="*" element={<PaginaNaoEncontrada />} />
          </Routes>
            <ScrollTop />
            <ConditionalFooter />
          </main>

        

      </div>
    </Router>
  );
}
function ConditionalFooter() {
  const location = useLocation();
  const showFooter = location.pathname !== '/contatos' &&  location.pathname !== '/XMR403SVT'; // Ocultar o footer na página de contato 

  return showFooter ? <Footer /> : null;
}

function ConditionalHeader(){
  const location = useLocation();
  const showHeader = location.pathname !== '/XMR403SVT'

  return showHeader ? (
    <>
      <Header/>
      <PopUp />
    </>
  ): null;
}


function PaginaNaoEncontrada(){
  return <div id="PaginaNaoEncontrada" >
    <div>
      <h1>404</h1>
      <p>Página não encontrada! </p>
      <img src={icone404} width="50px"/>
    </div>

    </div>
}


export default App;
