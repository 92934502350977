import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import seta from '../images/icons/sinal-de-seta-para-baixo-para-navegar.png'

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav id="principal" className="navbar">
      <ul>
        <li><Link to="/">Home</Link></li>
        
        {/* DROPDOWN */}
        <li>
          <a className='dropdown' href="#" onClick={(e) => { 
            e.preventDefault();
            toggleDropdown();
          }}>
            Equipamentos <img src={seta} width='10px' className={isDropdownOpen ? 'rotate' : ''} />
          </a>
          <ul id="equipamentos" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
            <li><Link to="/equipamento/mini-carregadeiras" onClick={() => setIsDropdownOpen(false)}>Mini carregadeira</Link></li>
            <li><Link to="/equipamento/manipuladores" onClick={() => setIsDropdownOpen(false)}>Mini manipulador</Link></li>
            <li><Link to="/equipamento/dumpers" onClick={() => setIsDropdownOpen(false)}>Mini dumpers</Link></li>
            <li><Link to="/equipamento/mini-escavadeiras" onClick={() => setIsDropdownOpen(false)}>Mini escavadeiras</Link></li>
            <li><Link to="/equipamento/compactadores" onClick={() => setIsDropdownOpen(false)}>Compactadores</Link></li>
            <li><Link to="/equipamento/valetadeiras" onClick={() => setIsDropdownOpen(false)}>Valetadeiras</Link></li>
            <li><Link to="/equipamento/esteiras-modulares" onClick={() => setIsDropdownOpen(false)}>Esteiras modulares</Link></li>
            <li><Link to="/equipamento/capinadeiras" onClick={() => setIsDropdownOpen(false)}>Roçadeiras e capinadeiras</Link></li>
            <li><Link to="/equipamento/implementos" onClick={() => setIsDropdownOpen(false)}>Implementos</Link></li>
          </ul>
        </li>
        <li><Link to="/sobre-nos">Sobre nós</Link></li>
        <li><Link to="/contatos">Contato</Link></li>   

        <li className='hidden'><a href="https://www.emestoque.com.br/lokmax">Seminovos</a></li>
        <li className='hidden'><a href="https://spa.checklistfacil.com.br/apply/b4c3c138-32d4-11ee-a82d-0e431841a12b/category/2205171">Avalie-nos </a></li>
        <li className='hidden'><a href="https://lokmax.solides.jobs/">Trabalhe conosco</a></li>                 
      </ul>
    </nav>
  );
}

export default Navbar;
