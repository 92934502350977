import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import nameImg from '../images/icons/user.png'
import emailImg from '../images/icons/e-mail.png'
import empresaImg from '../images/icons/predio.png'
import telefoneImg from  '../images/icons/chamada-telefonica-gray.png'

function Forms() {
  const form = useRef();
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [telefone, setTelefone] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_jhcnene', 'template_scb5phr', form.current, 'hyx_yQQOHMl5YCEGR')
      .then(
        () => {
          alert("Mensagem enviada com sucesso!");
        },
        (error) => {
          alert("Desculpe, não foi possível enviar sua mensagem. Estamos finalizando o formulário para garantir que sua experiência seja a melhor possível. Enquanto isso, você pode nos enviar uma mensagem pelo WhatsApp utilizando o botão no final da tela.");
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div>
        <img src={nameImg} alt="Nome"/>
        <input 
          type="text" 
          name="nome" 
          value={nome}
          placeholder="Nome Sobrenome" 
          onChange={e => setNome(e.target.value)}
          required 
        />
      </div>

      <div>
        <img src={emailImg} alt="Email"/>
        <input 
          type="email" 
          name="email" 
          value={email}
          placeholder="Email corporativo" 
          onChange={e => setEmail(e.target.value)}
          required 
        />
      </div>
      <div>
        <img src={empresaImg} alt="Empresa"/>
        <input 
          type="text" 
          name="empresa" 
          value={empresa}
          placeholder="Sua empresa" 
          onChange={e => setEmpresa(e.target.value)}
          required 
        />
      </div>
      <div>
        <img src={telefoneImg} alt="Telefone"/>
        <input 
          type="text" 
          name="telefone" 
          value={telefone}
          placeholder="Contato" 
          onChange={e => setTelefone(e.target.value)}
          required 
        />
      </div>
      <div>
        <textarea 
          name="message" 
          value={mensagem}
          placeholder="Mensagem" 
          onChange={e => setMensagem(e.target.value)}
          required 
        />
      </div>
      <input className="button-dark" type="submit" value="Enviar" />
    </form>
  );
}

export default Forms;
