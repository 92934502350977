import React from "react";

import linkIcon from '../images/icons/link-icon-white.png'
import wppIcon from '../images/icons/whatsapp-green.png'
import faceIcon from '../images/icons/facebook-icon-origin.png'
import youtubeIcon from '../images/icons/youtube-icon-vermelho.png'
import instaIcon from '../images/icons/instagram-icon-origin.png'
import telephone from '../images/icons/telephone-green.png'
import email from '../images/icons/email-green.png'
import linkedIcon from '../images/icons/linkedin-icon-azul.png'
import logoHorizontal from '../images/logos/lokmax-green.png';

function Footer(){
    return   <footer>
    <div id="footer-container">
        <section id="image-lokmax">
            <div id="area-img">
                <img src={logoHorizontal}/>
                <p>Qualidade e produtividade para a sua obra!</p>
            </div>
            
            <div id="map">
                <h5>Mapa do site:</h5>
                  
                <ul >
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="index.html">Home</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" href="blog.html" target="_blank">Blog</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="sobre_nos.html">Sobre Nós</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="contato.html"> Contato</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Trabalhe Conosco</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Avalie-nos</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                    <li className="d-flex">
                        <a className="ms-2" target="_blank" href="https://lokmax.solides.jobs/"> Contrato</a>
                        <img className="ms-2" src={linkIcon} />
                    </li>
                </ul>

            </div>

        </section>

        {/* <!--Contact--> */}
        <section id="contact">
                <h5>ENTRE EM CONTATO CONOSCO: </h5>
                <ul>
                {/* <!-- whatsapp --> */}
                <li className="d-flex ">
                    <img src={wppIcon} width="25px" />
                    <a target="_blank" href=" https://api.whatsapp.com/send/?phone=5531991518267&text">+55 (31) 99151-8267 </a>
                    <img src={linkIcon} className="ms-2" width="10px" height="10px" />
                </li>
    
                {/* <!-- telephone --> */}
                <li  className="d-flex">
                    <img src={telephone}width="24px" />
                    <a>+55 (31) 2581-9703</a>
                </li>
    
                {/* <!-- email --> */}
                <li className="d-flex">
                    <img src={email} width="25px" />
                    <a target="_blank" href="mailto:comercial@lokmax.com.br?subject=subject text">comercial@lokmax.com.br</a>
                    <img src={linkIcon} className="ms-2" width="10px" height="10px" />
                </li>
                </ul>

                <div id="social-media">
                    <h5>
                      SIGA-NOS:
                    </h5>
          
                    <ul>
                      {/* <!-- LINKEDIN --> */}
                      <li>
                        <a target="_blank" href="https://www.linkedin.com/company/lokmaxltda/"><img src={linkedIcon} /></a>
                      </li>
          
                      {/* <!-- YOUTUBE --> */}
                      <li>
                        <a target="_blank" href="https://www.youtube.com/@LOKMAX"><img src={youtubeIcon} /></a>
                      </li>
          
                      {/* <!--INSTAGRAM--> */}
                      <li>
                        <a target="_blank" href="https://www.instagram.com/lokmax_/"><img src={instaIcon} /></a>
                      </li>
                      {/* <!--INSTAGRAM--> */}
                      <li>
                        <a target="_blank" href="https://www.facebook.com/frederico.lokmax"><img src={faceIcon} /></a>
                      </li>
          
                    </ul>
          
                  </div>
        </section>
      
        <section>
            <h5>ONDE ESTAMOS:</h5>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3747.7750170986264!2d-43.98547742490711!3d-20.059864242365318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699b46638cacd%3A0x43965b17bf961ba8!2zTE9LTUFYIENPTcOJUkNJTyBFIExPQ0HDh8ODTyBERSBNw4FRVUlOQVM!5e0!3m2!1spt-BR!2sbr!4v1683132276439!5m2!1spt-BR!2sbr"
                width="" height="200px" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            <p>
                Horário comercial : Segunda à quinta de 7h às 17h e sexta de 7h às 16h
            </p>
        </section>
    </div>
</footer>
}


export default Footer;