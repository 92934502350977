import React, { useState } from 'react';
import '../style/AboutUs.css'
import EffectLogo from './EffectLogo';
import wackerNeuson from '../images/logos/Wacker_Neuson_logo.svg.png'
import mdb from '../images/logos/MDB-logo-removebg-preview.png'
import paladin from '../images/logos/paladin-logo.png'
import perfect from '../images/logos/perfect.png'
import timelineLokmax from '../images/TimelineCycle.jpg'


function AboutUs() {

  const [activeSection, setActiveSection] = useState('valores')

  
    return (
      <section id='AboutUs'>
        <section id='area-initial-ab'>
          <div className='area-text'>
          </div>
          
          <EffectLogo />

          <h4>Com 15 anos de mercado, estamos de visual novo, sempre trazendo as <span>melhores soluções em mecanização de mão de obra</span> .</h4>
        </section>

        <section className='sobre-nos'>
          <div className='text'>
            <h1>Sobre nós</h1>
            <p>A LOKMAX é uma empresa especializada no aluguel de equipamentos atendendo a diversos tipos de clientes e demandas. Atuamos através de uma locação consultiva, atentos aos desafios dos nossos clientes relacionados a mão de obra, a produtividade e a redução dos riscos. Isso nos motiva a sempre buscarmos soluções inovadoras de mecanização com a utilização de equipamentos de qualidade. A qualidade dos nossos equipamentos e o do nosso atendimento sempre foram diferenciais da nossa empresa, por isso estamos há mais de 10 anos no mercado e podemos afirmar que deixamos nossa marca registrada como símbolo de qualidade e produtividade nos nossos mais de 500 clientes atendidos.</p>
        
          </div>
        </section>

        <section className='distribuidores'>
          <div className='text-distribuidorse'>
            <h1>Distribuidores oficiais</h1>
            <p>
            Somos distribuidores oficiais das maiores fabricantes de equipamentos compactos do mundo. Importamos equipamentos inovadores em termos de tecnologia para atender nossos clientes
            </p>

          </div>
          <div className='img-distribuidores'>
            <img src={wackerNeuson} alt='Wacker Neuson' />
            <img src={mdb} alt='MDB'/>
            <img src={paladin} alt='Paladin' />
            <img src={perfect} alt='W-Perfect'/>
          </div>
        </section>

        <section className='history'>
          <div className='text'>
            <h1>Nossa História</h1>
            <p>
            A Lokmax foi fundada em 2009 a partir do sonho de dois irmãos, com o apoio de seu pai, de inovarem no mercado da construção civil com soluções diferenciadas 
            em mecanização de mão de obra. A partir disso, adquirimos nossas três primeiras mini carregadeiras e logo alocamos em obras de 
            construção civil, na região metropolitana de Belo Horizonte/MG. Ao longo dos anos nos especializamos no aluguel de equipamentos 
            atendendo a diversos tipos de clientes e demandas, adicionando ao nosso portfólio além de máquinas e equipamentos, operadores e 
            atendimento mecânico. 
            <br />
            <br />
            O ano de 2015 foi um marco importante na história da empresa, quando abrimos as portas para distribuição além 
            da locação de equipamentos e em 2019 firmamos de vez a distribuição com a maior fabricante de equipamentos compactos da Europa: 
            Wacker Neuson.
            </p>

          </div>
        </section>

        <section className='valores'>
            <div className='container-mvv'>
              <nav>
                <ul> 
                  <li onClick={() => setActiveSection('valores')} className={activeSection === 'valores' ? 'active' : ''}><p>Valores</p></li>
                  <li onClick={() => setActiveSection('missao')} className={activeSection === 'missao' ? 'active' : '' } ><p>Missão</p></li>
                  <li onClick={() => setActiveSection('visao')} className={activeSection === 'visao' ? 'active' :  ''}><p>Visão</p></li></ul>
                </nav>
              
                <div className='text'>
                    {activeSection === 'valores' &&(
                      <div id='valores'>
                        <p>
                          <li>Amor e respeito pela vida</li>
                          <li>Transparência e honestidade</li>
                          <li>Qualidade e excelência </li>
                          <li>Inovação e criatividade</li>
                          <li>Comprometimento</li>
                          <li>Produtividade</li>
                        </p>
                      </div>
                    )}
                    {activeSection === 'missao' &&(
                      <div id='missao'><p>“Somos uma empresa de locação e comercialização de equipamentos multicategoria com foco na mecanização de mão de obra, garantindo atendimento, suporte de excelência, segurança e produtividade.”</p></div> 
                    )}
                    {activeSection === 'visao' && (
                      <div id = 'visao'><p>“Gerar valor para nossos stakeholders e ser a primeira opção na locação e comercialização de equipamentos multicategoria, assegurando um crescimento no faturamento de 30% ao ano, atraindo investidores e parceiros estratégicos.”</p></div>
                    )}
                </div>

            </div>

             <div className='timeLine'>
             <img src={timelineLokmax} />
             </div>
    
        </section>

      </section>

     

      
    )
}

export default AboutUs;
