import React, { startTransition } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cliente1 from '../images/clientsLogo/ArenaMRv.png'
import cliente2 from '../images/clientsLogo/Emccamp.png'
import cliente3 from '../images/clientsLogo/AndradeGuitierrez.png'
import cliente4 from '../images/clientsLogo/EixoSp.png'
import cliente5 from '../images/clientsLogo/Terraco.png'
import cliente6 from '../images/clientsLogo/ADMotta.png'
import cliente7 from '../images/clientsLogo/Conata.png'
import cliente8 from '../images/clientsLogo/Racional.png'
import cliente9 from '../images/clientsLogo/Precon.png'
import cliente10 from '../images/clientsLogo/Mezenergia.png'
import cliente11 from '../images/clientsLogo/MelloAzevedo.png'
import cliente12 from '../images/clientsLogo/Direcional.png'
import cliente13 from '../images/clientsLogo/MRV.png'

const customers = [
    { id: 1, logo: cliente1 },
    { id: 2, logo: cliente2 },
    { id: 3, logo: cliente3 },
    { id: 4, logo: cliente4 },
    { id: 5, logo: cliente5 },
    { id: 6, logo: cliente6 },
    { id: 7, logo: cliente7 },
    { id: 8, logo: cliente8 },
    { id: 9, logo: cliente9 },
    { id: 10, logo: cliente10 },
    { id: 11, logo: cliente11 },
    { id: 12, logo: cliente12 },
    { id: 13, logo: cliente13 },
  
  ];

function CustomerCarousel (){
      const settings = {
        dots: false,
        infinite: true,
        speed: 2000,  // Ajuste a velocidade de deslize aqui (em ms)
        slidesToShow: 4,  // Mostre 3 slides ao mesmo tempo
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,  // Define para 0 para um deslize contínuo
        cssEase: 'linear',  // Define o tipo de animação
        pauseOnHover: true,
        arrows: false,
      };


        return (
        <section id="clients">
        <h2>Conheça alguns de nossos clientes:</h2>
         <div className="slider">
           {/* <!--AREA DE LOGO DOS CLIENTES--> */}
           <Slider {...settings} className="Slider">
            {customers.map(customer => (
              <div key={customer.id} className="customer-slide">
                <img src={customer.logo}/>
              </div>
            ))}
          </Slider>
         </div>

      </section>
    
        )
}
export default CustomerCarousel
